// // routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// // components
// import SvgIconStyle from '../../components/SvgIconStyle';
// import { HealthAndSafety, Language, School, TravelExplore, } from '@material-ui/icons';
// import checkVisa from '@iconify/icons-solar/diploma-verified-linear';
// import bank from '@iconify/icons-fluent/building-bank-16-regular';
// import { Icon } from '@iconify/react';


// // ----------------------------------------------------------------------

// const getIcon = (name) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   visa: getIcon('ic_visa'),
//   kanban: getIcon('ic_kanban')
// };

// const sidebarConfig = [
//   // GENERAL
//   // ----------------------------------------------------------------------
//   {
//     subheader: 'general',
//     items: [
//       // {
//       //   title: 'app',
//       //   path: PATH_DASHBOARD.general.app,
//       //   icon: ICONS.dashboard
//       // },
//       // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      
//       {
//         title: 'visa',
//         path: PATH_DASHBOARD.visa.root,
//         icon: ICONS.visa,
//         children: [
//           { title: 'country', path: PATH_DASHBOARD.visa.country },
//           // { title: 'Country Visa Requirement', path: PATH_DASHBOARD.visa.typeOfVisa },
//           // { title: 'required documents', path: PATH_DASHBOARD.visa.DocumentsRequired },
//           // { title: 'travel advice', path: PATH_DASHBOARD.visa.travelAdvice },
//           // { title: 'customs info', path: PATH_DASHBOARD.visa.customsInfo },
//         ]
//       },
//       {
//         title: 'check my visa',
//         path: PATH_DASHBOARD.checkMyvisa.root,
//         icon: <Icon width={50} height={50} icon={checkVisa}/>,
//         children: [
//           { title: 'listing', path: PATH_DASHBOARD.checkMyvisa.checkvisa },
//           // { title: 'required documents', path: PATH_DASHBOARD.checkMyvisa.checkMyVisaDocumentsRequired },
//         ]
//       },
//       {
//         title: 'PR-Pathway',
//         path: PATH_DASHBOARD.prPathway.root,
//         icon: <span class="carbon--flight-international" style={{width:50,height:50}}></span>,
//         children: [
//           { title: 'listing', path: PATH_DASHBOARD.prPathway.root },
//         ]
//       },
//       {
//         title: 'insurance',
//         path: PATH_DASHBOARD.insurance.root,
//         icon: <HealthAndSafety/>,
//         children: [,
//           { title: 'listing', path: PATH_DASHBOARD.insurance.insuranceListing },
//           { title: 'add benefits', path: PATH_DASHBOARD.insurance.benefits },
//           // { title: 'why you choose', path: PATH_DASHBOARD.insurance.whyYouChoose },
//           // { title: 'FAQs', path: PATH_DASHBOARD.insurance.faq },
//         ]
//       },
//       {
//         title: 'universities',
//         path: PATH_DASHBOARD.universities.root,
//         icon: <School />,
//         children: [
//           { title: 'listing', path: PATH_DASHBOARD.universities.universityListing },
//           // { title: 'required documents', path: PATH_DASHBOARD.universities.DocumentsRequired },
//           // { title: 'all about the course', path: PATH_DASHBOARD.universities.AllAboutTheCourse },
//         ]
//       },
//       {
//         title: 'consultants',
//         path: PATH_DASHBOARD.consultants.root,
//         icon: <span class="icon-park-twotone--in-flight" style={{width:60,height:60}}></span>,
//         children: [
//           { title: 'listing', path: PATH_DASHBOARD.consultants.consultantsListing },
//           // { title: 'consultants staffs', path: PATH_DASHBOARD.consultants.consultantsStaff },
//           // { title: 'service locations', path: PATH_DASHBOARD.consultants.serviceLocation },
//           // { title: 'offers', path: PATH_DASHBOARD.consultants.offers },
//         ]
//       },
//       {
//         title: 'languages',
//         path: PATH_DASHBOARD.languages.root,
//         icon: <Language />,
//         // icon: <span class="game-icons--money-stack" style={{width:60,height:60}}></span>,
//         children: [
//           { title: 'listing', path: PATH_DASHBOARD.languages.languagesListing },
//           // { title: 'language staffs', path: PATH_DASHBOARD.languages.languageStaff },
//           // { title: 'service locations', path: PATH_DASHBOARD.languages.serviceLocation },
//           // { title: 'offers', path: PATH_DASHBOARD.languages.offers },
//         ]
//       },
//       {
//         title: 'Credits',
//         path: PATH_DASHBOARD.credits.root,
//         icon: <span class="game-icons--money-stack" style={{width:60,height:60}}></span>,
//         children: [
//           { title: 'listing', path: PATH_DASHBOARD.credits.root },
//           // { title: 'required documents', path: PATH_DASHBOARD.credits.DocumentsRequired },
//           // { title: 'data variables', path: PATH_DASHBOARD.credits.CreditsDataVariables },
//         ]
//       },
//       // {
//       //   title: 'user',
//       //   path: PATH_DASHBOARD.user.root,
//       //   icon: ICONS.user,
//       //   children: [
//       //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
//       //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
//       //     { title: 'list', path: PATH_DASHBOARD.user.list },
//       //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
//       //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
//       //     { title: 'account', path: PATH_DASHBOARD.user.account }
//       //   ]
//       // },
//       // MANAGEMENT : BLOG
//       // {
//       //   title: 'blog',
//       //   path: PATH_DASHBOARD.blog.root,
//       //   icon: ICONS.blog,
//       //   children: [
//       //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
//       //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
//       //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
//       //   ]
//       // },
//     ]
//   },

//   // MANAGEMENT
//   // ----------------------------------------------------------------------
//   // {
//   //   subheader: 'management',
//   //   items: [
//   //     // MANAGEMENT : USER
//   //     // {
//   //     //   title: 'user',
//   //     //   path: PATH_DASHBOARD.user.root,
//   //     //   icon: ICONS.user,
//   //     //   children: [
//   //     //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
//   //     //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
//   //     //     { title: 'list', path: PATH_DASHBOARD.user.list },
//   //     //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
//   //     //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
//   //     //     { title: 'account', path: PATH_DASHBOARD.user.account }
//   //     //   ]
//   //     // },

//   //     // MANAGEMENT : E-COMMERCE
//   //     // {
//   //     //   title: 'e-commerce',
//   //     //   path: PATH_DASHBOARD.eCommerce.root,
//   //     //   icon: ICONS.cart,
//   //     //   children: [
//   //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
//   //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
//   //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
//   //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
//   //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
//   //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
//   //     //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
//   //     //   ]
//   //     // },

//   //     // MANAGEMENT : BLOG
//   //     // {
//   //     //   title: 'blog',
//   //     //   path: PATH_DASHBOARD.blog.root,
//   //     //   icon: ICONS.blog,
//   //     //   children: [
//   //     //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
//   //     //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
//   //     //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
//   //     //   ]
//   //     // }
//   //   ]
//   // },

//   // APP
//   // ----------------------------------------------------------------------
//   // {
//   //   subheader: 'app',
//   //   items: [
//   //     { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
//   //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
//   //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
//   //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
//   //   ]
//   // }
// ];

// export default sidebarConfig;







// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { HealthAndSafety, Language, QueryStats, School, TravelExplore, } from '@material-ui/icons';
import checkVisa from '@iconify/icons-solar/diploma-verified-linear';
import bank from '@iconify/icons-fluent/building-bank-16-regular';
import { Icon } from '@iconify/react';
import { visaUserType } from 'src/contexts/JWTContext';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  visa: getIcon('ic_visa'),
  kanban: getIcon('ic_kanban')
};



let sidebarConfig = [];
let userType = JSON.parse(window.localStorage.getItem('user_type'))
let mainId = JSON.parse(window.localStorage.getItem('mainId'))
// console.log("visaUserType",visaUserType)
// console.log("mainId",mainId)

if(userType === 1) {

 sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader:  `general ${visaUserType} userType ${userType}`,
    subheader:  `general`,
    items: [
   
      {
        title: 'visa',
        path: PATH_DASHBOARD.visa.root,
        icon: ICONS.visa,
        children: [
          { title: 'country', path: PATH_DASHBOARD.visa.country },
 
        ]
      },
      {
        title: 'check my visa',
        path: PATH_DASHBOARD.checkMyvisa.root,
        icon: <Icon width={50} height={50} icon={checkVisa}/>,
        children: [
          { title: 'listing', path: PATH_DASHBOARD.checkMyvisa.checkvisa },
        ]
      },
      {
        title: 'PR-Pathway',
        path: PATH_DASHBOARD.prPathway.root,
        icon: <span class="carbon--flight-international" style={{width:50,height:50}}></span>,
        children: [
          { title: 'listing', path: PATH_DASHBOARD.prPathway.root },
        ]
      },
      {
        title: 'insurance',
        path: PATH_DASHBOARD.insurance.root,
        icon: <HealthAndSafety/>,
        children: [,
          { title: 'listing', path: PATH_DASHBOARD.insurance.insuranceListing },
          { title: 'enquiries', path: PATH_DASHBOARD.insurance.enquiries },
    
        ]
      },
      {
        title: 'universities',
        path: PATH_DASHBOARD.universities.root,
        icon: <School />,
        children: [
          { title: 'listing', path: PATH_DASHBOARD.universities.universityListing },
          { title: 'enquiry', path: PATH_DASHBOARD.universities.enquiryListing },
        ]
      },
      {
        title: 'consultants',
        path: PATH_DASHBOARD.consultants.root,
        icon: <span class="icon-park-twotone--in-flight" style={{width:60,height:60}}></span>,
        children: [
          { title: 'listing', path: PATH_DASHBOARD.consultants.consultantsListing },
          { title: 'service enquires', path: PATH_DASHBOARD.enquiries.serviceList },
        ]
      },
      {
        title: 'language Consultants',
        path: PATH_DASHBOARD.languages.root,
        icon: <Language />,
        children: [
          { title: 'listing', path: PATH_DASHBOARD.languages.languagesListing },
          { title: 'enquiry', path: PATH_DASHBOARD.languages.enquiries },
       
        ]
      },
      {
        title: 'Credits',
        path: PATH_DASHBOARD.credits.root,
        icon: <span class="game-icons--money-stack" style={{width:60,height:60}}></span>,
        children: [
          { title: 'listing', path: PATH_DASHBOARD.credits.root },
          { title: 'enquiry', path: PATH_DASHBOARD.credits.enquiryListing },
        ]
      },
      {
        title: 'enquiries',
        path: PATH_DASHBOARD.enquiries.root,
        icon: <QueryStats/>,
        children: [
          { title: 'website enquires', path: PATH_DASHBOARD.enquiries.root},
          // { title: 'service enquires', path: PATH_DASHBOARD.enquiries.serviceList },
        ]
      },
    ]
  },


];
}
else if( userType === 4) {
  sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      // subheader:  `general ${visaUserType} userType ${userType}`,
      subheader:  `general`,
      items: [
     
        {
          title: 'insurance',
          path: PATH_DASHBOARD.insurance.root,
          icon: <HealthAndSafety/>,
          children: [
            { title: 'Choose right cover', path: PATH_DASHBOARD.insurance.chooseRightCover},
            { title: 'why you choose', path: PATH_DASHBOARD.insurance.whyYouChoose },
            { title: 'FAQs', path: PATH_DASHBOARD.insurance.faq },
            { title: 'add benefits', path: PATH_DASHBOARD.insurance.benefits },
            { title: 'enquiries', path: PATH_DASHBOARD.insurance.enquiries },
          ]
        },
       
        
      ]
    },
  
  
  ];
}
else if( userType === 5) {
  sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      // subheader: `general ${visaUserType} userType ${userType}`,
      subheader: `general `,
      items: [
     
       
        {
          title: 'universities',
          path: PATH_DASHBOARD.universities.root,
          icon: <School />,
          children: [
            // { title: 'listing', path: PATH_DASHBOARD.universities.universityListing },
            { title: 'enquiry', path: PATH_DASHBOARD.universities.enquiryListing },
          ]
        },
        
       
        
      ]
    },
  
  
  ];
}
else if( userType === 8) {
  sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      // subheader: `general ${visaUserType} userType ${userType}`,
      subheader: `general `,
      items: [
     
       
        {
          title: 'Credits',
          path: PATH_DASHBOARD.credits.root,
          icon: <span class="game-icons--money-stack" style={{width:60,height:60}}></span>,
          children: [
            { title: 'enquiry', path: PATH_DASHBOARD.credits.enquiryListing },
          ]
        },
        
       
        
      ]
    },
  
  
  ];
}
else if( userType === 6) {
  sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      // subheader: `general ${visaUserType} userType ${userType}`,
      subheader: `general `,
      items: [
     
       
        // {
        //   title: 'consultants',
        //   path: PATH_DASHBOARD.consultants.root,
        //   icon: <span class="icon-park-twotone--in-flight" style={{width:60,height:60}}></span>,
        //   children: [
  
        //   ]
        // },
        {title: 'consultants',
          path: PATH_DASHBOARD.consultants.root,
          icon: <span class="icon-park-twotone--in-flight" style={{width:60,height:60}}></span>,
          children: [
            // { title: 'Details', path: PATH_DASHBOARD.consultants.consultantsDetails },
            { title: 'service enquires', path: PATH_DASHBOARD.enquiries.serviceList },
            { title: 'consultants staffs', path: PATH_DASHBOARD.consultants.consultantsStaff },
            { title: 'service locations', path: PATH_DASHBOARD.consultants.serviceLocation },
            { title: 'offers', path: PATH_DASHBOARD.consultants.offers },
          ]
        },
       
        
      ]
    },
  
  
  ];
}

else if( userType === 7) {
  sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      // subheader: `general ${visaUserType} userType ${userType}`,
      subheader: `general `,
      items: [
     
       
        {
          title: 'languages',
          path: PATH_DASHBOARD.languages.root,
          icon: <Language />,
          children: [
          { title: 'language staffs', path: PATH_DASHBOARD.languages.languageStaff },
          { title: 'service locations', path: PATH_DASHBOARD.languages.serviceLocation },
          { title: 'offers', path: PATH_DASHBOARD.languages.offers },
          { title: 'enquiry', path: PATH_DASHBOARD.languages.enquiries },
         
          ]
        },
        
       
        
      ]
    },
  
  
  ];
}
else {
  sidebarConfig = [];
}

export default sidebarConfig;
